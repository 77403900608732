<template>
	<div class="section_right">
		<!--section_right-->
		<div class="sub_top">
			<!--sub_top-->
			<div class="sub_title">
				<i class="far fa-lock-alt text-primary"></i>{{$t("change_password.1")}}</div>
		</div>
		<!--sub_top end-->
		<div class="section_right_inner">
			<!--section_right_inner-->
			<div class="s_left">
				<div class="mypage_section01">
					<!--withdrawal_section01-->
					<p class="mypage_section01_t">{{$t("change_password.2")}}</p>
					<div class="mypage_input_box">
						<!--withdrawal_input_box-->
						<div name="loginfrm" method="post">
							<table style="width:100%;">
								<tbody>
									<tr>
										<td>{{$t("change_password.3")}}</td>
										<td>
											<input type="password" name="oldpass" :placeholder="`${$t('change_password.6')}`" class="withdrawal_input01" v-model="password">
										</td>
									</tr>
									<tr>
										<td>{{$t("change_password.4")}}</td>
										<td>
											<input type="password" name="passwd" :placeholder="`${$t('change_password.7')}`" class="withdrawal_input01"  v-model="new_password">
										</td>
									</tr>
									<tr>
										<td>{{$t("change_password.5")}}</td>
										<td>
											<input type="password" name="conf_pass"  :placeholder="`${$t('change_password.8')}`" class="withdrawal_input01"  v-model="new_conf_password">
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<!--withdrawal_input_box end-->
					<div style="text-align: center;">
						<button class="withdrawal_botton"  @click="ChangePass()">{{$t('change_password.9')}}</button>
					</div>
				</div>
				<!--withdrawal_section01 end-->
			</div>
		</div>
		<!--section_right_inner end-->
	</div>
	<!--section_right end-->
</template>
<script>

const CryptoJS = require("crypto-js");
	export default {
		data(){
			return{
				password:'',
				new_password:'',
				new_conf_password:'',
			}
		},
	    mounted(){
	
		},
		methods : {
			ChangePass : function(){
				const password = this.password;
				const new_password = this.new_password;
				const new_conf_password = this.new_conf_password;

				if(password ==''){
					this.$alert(this.$t('change_password.10'));
					return false;
				}

				if(new_password ==''){
					this.$alert(this.$t('change_password.11'));
					return false;
				}

				if(new_password != new_conf_password){
					this.$alert(this.$t('change_password.12'));
					return false;
				}

				const body = {password,new_password};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/profile/ChangePassword`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								this.$alert(this.$t('change_password.13')).then(
									()=>{
										this.$router.go();
									}
								)	
							}else if(res.data.code=='100'){
								this.$alert(this.$t('change_password.14'));	
							}
						}

					}
				).catch(() => {});
				
			}
		}
	}
</script>
<style>
</style>