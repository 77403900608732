<template>
	<div class="section_right">
		<!--section_right-->
		<div class="sub_top">
			<!--sub_top-->
			<div class="sub_title">
				<i class="fas fa-qrcode text-primary"></i>{{$t("otp.1")}}</div>
		</div>
		<!--sub_top end-->
		<div class="section_right_inner">
			<!--section_right_inner-->
			<div class="s_left">
				<div class="withdrawal01" >
					<!--withdrawal_section01-->
					<p class="mypage_section01_t">{{$t('otp.2')}}</p>
					<div class="mypage_input_box" v-if="otp_able=='N'">
						<!--withdrawal_input_box-->
						<div name="profilefrm" method="post" >
							<table style="width:100%;">
								<tbody>
									<tr>
										<td></td>
										<td style="text-align: center;">
											<vue-qrcode :value="qr_url" style='width:150px;margin:auto;'/>
										</td>
									</tr>
									<tr>
										<td>{{$t('otp.3')}}</td>
										<td>
											<input type="text" name="addres" id="recaddr" class="withdrawal_input01" v-model="otp" ref="balanceRef"  autocomplete='off' readonly onclick="copy_to_clipboard()">
										</td>
									</tr>
									<tr>
										<td>{{$t('otp.4')}}</td>
										<td>
											<input type="text" name="addres" class="withdrawal_input01" v-model="otp_code"  autocomplete='off'>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div style="text-align: center;">
							<input type="button" name="withok" class="withdrawal_botton" :value="$t('otp.5')" @click ="OtpAuth()">
						</div>
					</div>

					<div class="mypage_input_box" v-if="otp_able=='Y'">
						<!--withdrawal_input_box-->
						<div name="profilefrm" method="post" >
							<table style="width:100%;">
								<tbody>
									<tr>
										<td>{{$t('otp.3')}}</td>
										<td>
											<input type="text" name="addres" id="recaddr" class="withdrawal_input01" v-model="otp" ref="balanceRef"  autocomplete='off' readonly onclick="copy_to_clipboard()">
										</td>
									</tr>
									
								</tbody>
							</table>
						</div>
					</div>
					<!--withdrawal_input_box end-->
				</div>
				<!--withdrawal_section01 end-->
			</div>
		</div>
		<!--section_right_inner end-->
	</div>
	<!--section_right end-->
</template>
<script>
const CryptoJS = require("crypto-js");

import VueQrcode from 'vue-qrcode'

	export default {
		components: {
			VueQrcode,
		},
		data(){
			return{
				otp_able : '',
				otp : 't',
				otp_code : '',
				auth_code : '',
				
			}
		},
	    mounted(){
			this.OtpGenerate();
	
		},
		methods : {
			OtpGenerate(){
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/profile/OtpGenerate`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)

								this.otp = body.otp_code;
								this.qr_url = body.qr_url;
								this.CheckOTP()
							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			CheckOTP(){
				
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/profile/CheckOTP`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)

								this.otp_able = body.otp_able;
								if(body.otp_able =='Y'){
									this.otp = body.otp;
									this.qr_url = body.otp;
								}
								

							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			OtpAuth(){
				const otp_code = this.otp_code;
				const otp = this.otp;

				if(otp_code ==''){
					this.$alert(this.$t('otp.6'))
					return false;
				}

				const body = {otp_code,otp};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/profile/OtpAuth`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								this.$alert(this.$t('otp.7')).then(
									()=>{
										this.$router.go();
									}
								)

							}else if(res.data.code=='100'){
								this.$alert(this.$t('otp.8'))
							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});

			},
			
		}
	}
</script>
<style>
</style>