<template>
	<div class="section_right">
		<!--section_right-->
		<div class="sub_top">
			<!--sub_top-->
			<div class="sub_title">
				<i class="far fa-user text-primary"></i>{{$t("profile.1")}}
			</div>
		</div>
		<!--sub_top end-->
		<div class="section_right_inner">
			<!--section_right_inner-->
			<div class="s_left">
				<div class="mypage_section01">
					<!--withdrawal_section01-->
					<p class="mypage_section01_t">{{$t("profile.2")}}
						<!-- <span style="text-align: center;">
							<button type="button" class="withdrawal_botton " style="width:100px;height:30px;margin-left:20px;color:#333" @click="GotoChangePassword()">비밀번호 변경</button>
							<button type="button" class="withdrawal_botton " style="width:100px;height:30px;margin-left:20px;color:#333" @click="GotoChangePassword()">핀번호 변경</button>
						</span> -->
					</p>
					<div class="mypage_input_box">
						<!--withdrawal_input_box-->
						<div name="loginfrm" method="post">
							<table style="width:100%;">
								<tbody>
									<tr>
										<td>{{$t("profile.3")}}</td>
										<td>
											<input type="text" name="passwd"  class="withdrawal_input01"  v-model="id" readonly>
										</td>
									</tr>
									<tr>
										<td>{{$t("profile.4")}}</td>
										<td>
											<input type="text" name="conf_pass"  :placeholder="`${$t('profile.8')}`" class="withdrawal_input01"  v-model="name">
										</td>
									</tr>
									
									<tr>
										<td>{{$t("profile.5")}}</td>
										<td>
											<input type="text" name="conf_pass"  :placeholder="`${$t('profile.9')}`" class="withdrawal_input01"  v-model="email">
										</td>
									</tr>
									<tr>
										<td>{{$t("profile.6")}}</td>
										<td>
											<input type="text" name="conf_pass"  :placeholder="`${$t('profile.10')}`" class="withdrawal_input01"  v-model="phone">
										</td>
									</tr>
									<tr>
										<td>{{$t("profile.15")}}</td>
										<td>
											<input type="text" name="conf_pass"  :placeholder="`${$t('profile.17')}`" class="withdrawal_input01"  v-model="bnb_wallet">
										</td>
									</tr>
									<tr>
										<td>{{$t("profile.16")}}</td>
										<td>
											<input type="text" name="conf_pass"  :placeholder="`${$t('profile.17')}`" class="withdrawal_input01"  v-model="trx_wallet">
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<!--withdrawal_input_box end-->
					<div style="text-align: center;">
						<input type="button" name="submit" class="withdrawal_botton"  :value="`${$t('profile.7')}`" @click="ChangeInfo()">
					</div>
				</div>
				<!--withdrawal_section01 end-->
			</div>
		</div>
		<!--section_right_inner end-->
	</div>
	<!--section_right end-->
</template>
<script>
const CryptoJS = require("crypto-js");

	export default {
		data(){
			return{
				id:'',
				name:'',
				email:'',
				phone:'',
				address:'',
				bnb_wallet : '',
				trx_wallet : '',
			}
		},
	    created(){
			this.GetProfile()
	
		},
		methods : {
			GotoChangePassword(){
				const link = `/${this.$i18n.locale}/changePw`
				this.$router.push({path:link})

			},
			GetProfile(){
				
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/profile/GetProfileInfo`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)
								
								this.name = body.info.name;
								this.email = body.info.email;
								this.phone = body.info.phone
								this.id = body.info.id
								this.bnb_wallet = body.info.bnb;
								this.trx_wallet = body.info.trx;

							}
						}

					}
				).catch(() => {});
			},
			ChangeInfo : function(){
				const name = this.name;
				const email = this.email;
				const phone = this.phone;
				const bnb = this.bnb_wallet;
				const trx = this.trx_wallet;

				if(name ==''){
					this.$alert(this.$t('profile.11'));
					return false;
				}

				if(email ==''){
					this.$alert(this.$t('profile.12'));
					return false;
				}

				if(phone ==''){
					this.$alert(this.$t('profile.13'));
					return false;
				}
				
				const body = {name,email,phone,trx,bnb};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/profile/ChangeInfo`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								this.$alert(this.$t('profile.14')).then(
									()=>{
										this.GetProfile();
									}
								)
								

							}
						}

					}
				).catch(() => {});
			}
		}
	}
</script>
<style>
</style>